
import { Component, Prop, Provide, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
  },
  computed: {}
})
export default class CommentList extends Vue {
  mounted() {
    const _this: any = this;
  }
}
